<template>
  <div class="sheets">
    <div class="container is-fluid">
      <page-header :entity="$EntitiesName.Sheet" :create="openCreateModal">
        <h1 class="title">Fiches Affaire</h1>
        <template #actions>
          <button
            v-if="options.includes($UserOptions.BUDGET_CONTROLS.VALIDATE)"
            class="button is-info"
            :disabled="!sheetsSelected.length"
            @click="validateSheets()"
          >
            Valider
          </button>
          <button
            v-if="options.includes($UserOptions.BUDGET_CONTROLS.HISTORIZE)"
            class="button is-success"
            @click="openHistorizationModal()"
          >
            Historiser
          </button>
          <button
            v-if="options.includes($UserOptions.BUDGET_CONTROLS.HISTORIZE)"
            class="button is-success"
            @click="$refs.resetRadModal.open()"
          >
            RAZ RAD
          </button>
        </template>
      </page-header>

      <datatable
        :fetch="$Api.Sheet.fetchSheets"
        :entity="$EntitiesName.Sheet"
        ref="sheetList"
        enableDownload
        v-model="sheetsSelected"
      >
        <div
          slot="project"
          slot-scope="{ item: { project } }"
          title="Projet"
          :sortable="true"
          sortBy="project.name"
        >
          {{ project && project.name }}
        </div>
        <div
          slot="milestone"
          slot-scope="{ item }"
          title="Jalon"
          :sortable="true"
        >
          {{ item.milestone }}
        </div>
        <div slot="name" slot-scope="{ item }" title="Nom" :sortable="true">
          {{ item.name }}
        </div>
        <div
          slot="imputations"
          slot-scope="{ item }"
          title="eOTP"
          :sortable="true"
          sortBy="imputations.eotp"
        >
          <span
            v-for="imputation in item.imputations"
            :key="'i_' + imputation.eotp"
            class="tag is-info is-light"
            >{{ imputation.eotp }}</span
          >
        </div>
        <div
          slot="owner"
          slot-scope="{ item: { owner } }"
          title="Responsable"
          :sortable="true"
          sortBy="owner.name"
        >
          {{ owner && owner.name }}
        </div>
        <div
          slot="endDate"
          slot-scope="{ item }"
          title="Date de fin de l'affaire"
          v-if="item.endDate"
          :sortable="true"
          type="date"
        >
          {{ item.endDate | reverseDate }}
        </div>
        <div
          slot="endDate"
          title="Date de fin de l'affaire"
          v-else
          :sortable="true"
          type="date"
        ></div>
        <div
          slot="status"
          slot-scope="{ item }"
          title="Statut de l'affaire "
          :sortable="true"
          sortBy="sheetStatus.name"
        >
          {{ item.sheetStatus && item.sheetStatus.name }}
        </div>
        <div
          slot="administrationStatus"
          slot-scope="{ item }"
          title="Statut de gestion "
          :sortable="true"
          sortBy="administrationStatus.name"
        >
          {{ item.administrationStatus && item.administrationStatus.name }}
        </div>
        <div
          slot="state"
          slot-scope="{ item: { state } }"
          title="Etat"
          :sortable="true"
          sortBy="state.name"
        >
          <span class="tag" :class="state && $Colors[state.code]">{{
            state && state.name
          }}</span>
        </div>
      </datatable>
    </div>

    <modal
      ref="createSheetModal"
      @create="createSheet"
      title="Création d'une fiche affaire"
    >
      <ValidationObserver ref="form">
        <div class="columns">
          <div class="column is-half">
            <text-field
              label="Nom"
              v-model="sheet.name"
              :inline="false"
              :edit="true"
              required
              data-test="sel-sheet-name"
            ></text-field>
            <many2one-field
              label="Type de contrôle budgétaire"
              v-model="sheet.controlType"
              :fetch="$Api.Sheet.fetchControlTypes"
              reference="name"
              entity="controlType"
              :columns="{ name: 'Nom' }"
              :hasFilter="true"
              :inline="false"
              :edit="true"
              required
            ></many2one-field>
            <many2one-field
              v-if="
                sheet.controlType &&
                sheet.controlType.code === ControlTypeCodes.IMPUTATIONS
              "
              label="Projet"
              v-model="sheet.project"
              :fetch="$Api.Project.fetchProjects"
              reference="name"
              entity="project"
              :columns="projectsColumns"
              :hasFilter="true"
              :inline="false"
              :edit="true"
              required
            ></many2one-field>
            <many2one-field
              v-if="
                sheet.controlType &&
                sheet.controlType.code === ControlTypeCodes.ACCOUNTING_NATURES
              "
              label="Modèle de fiche"
              v-model="sheet.sheetTemplate"
              :fetch="$Api.SheetTemplate.fetchSheetTemplates"
              reference="name"
              entity="sheetTemplate"
              :columns="sheetTemplateColumns"
              :hasFilter="true"
              :inline="false"
              :edit="true"
            ></many2one-field>
          </div>
          <div class="column is-half">
            <many2one-field
              label="Responsable"
              v-model="sheet.owner"
              :fetch="$Api.User.fetchUsers"
              reference="name"
              entity="user"
              :columns="{ name: 'Nom' }"
              :hasFilter="true"
              :inline="false"
              :edit="true"
              required
            ></many2one-field>
            <many2one-field
              label="Statut de l'affaire"
              v-model="sheet.sheetStatus"
              :fetch="$Api.SheetStatus.fetchSheetStatus"
              reference="name"
              entity="sheetStatus"
              :columns="sheetStatusColumns"
              :hasFilter="true"
              :inline="false"
              :edit="true"
              required
            ></many2one-field>
            <many2one-field
              label="Statut de gestion"
              v-model="sheet.administrationStatus"
              :fetch="$Api.AdministrationStatus.fetchAdministrationStatuses"
              reference="name"
              entity="sheetStatus"
              :columns="{ name: 'Statuts de gestion' }"
              :hasFilter="true"
              :inline="false"
              :edit="true"
              required
            ></many2one-field>
            <text-field
              label="Jalon"
              v-model="sheet.milestone"
              :inline="false"
              :edit="true"
            ></text-field>
          </div>
        </div>
      </ValidationObserver>
    </modal>

    <modal
      ref="historizationModal"
      @create="historizeBudgetControlsForProjects"
      action="Historiser"
      title="Sélection des projets à historiser"
    >
      <div class="columns">
        <div class="column is-half">
          <ValidationObserver ref="historizationForm">
            <many2one-field
              label="Projets"
              v-model="projectsToHistorize"
              :fetch="$Api.Project.fetchProjects"
              reference="name"
              :entity="$EntitiesName.Project"
              :columns="{ name: 'Nom' }"
              :hasFilter="true"
              :inline="false"
              :edit="true"
              required
            ></many2one-field>
            <selector-field
              :options="$Api.Sheet.getMonths"
              label="Mois"
              v-model="month"
              :inline="false"
              :edit="true"
            ></selector-field>
            <selector-field
              :options="$Api.Sheet.getYears"
              label="Année"
              v-model="year"
              :inline="false"
              :edit="true"
            ></selector-field>
          </ValidationObserver>
        </div>
      </div>
    </modal>

    <modal
      ref="resetRadModal"
      @create="resetRadForProjects"
      action="RAZ RAD"
      title="Sélection des projets"
    >
      <div class="columns">
        <div class="column is-half">
          <ValidationObserver ref="resetRadForm">
            <many2one-field
              label="Projets"
              v-model="projectsToHistorize"
              :fetch="$Api.Project.fetchProjects"
              reference="name"
              :entity="$EntitiesName.Project"
              :columns="{ name: 'Nom' }"
              :hasFilter="true"
              :inline="false"
              :edit="true"
              required
            ></many2one-field>
          </ValidationObserver>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Datatable from "../../components/elements/Datatable";
import { Codes as ControlTypeCodes } from "../../constants/ControlType";

export default {
  name: "sheets",
  components: {
    Datatable,
  },
  data() {
    return {
      ControlTypeCodes,
      sheet: {},
      sheetTemplateColumns: {
        "project.name": "Projet",
        name: "Modèle de fiche affaire",
      },
      sheetStatusColumns: {
        name: "Statuts",
      },
      ownersColumns: {
        name: "Utilisateur",
      },
      projectsColumns: {
        name: "Projet",
        "region.name": "Région",
        "region.direction.name": "Direction",
      },
      month: null,
      year: null,
      projectsToHistorize: [],
      sheetsSelected: [],
    };
  },
  computed: {
    ...mapGetters({
      options: "Auth/getOptions",
      isHistorizing: "App/getIsHistorizing",
    }),
    selectedToUrl() {
      return this.sheetsSelected.map((object) => object.id).toString();
    },
  },
  watch: {
    "sheet.sheetTemplate"(val) {
      if (!val) return;
      this.sheet.project = val.project;
    },
    isHistorizing(val) {
      if (!val) {
        this.$refs.sheetList.refresh();
      }
    },
  },
  methods: {
    openCreateModal() {
      this.$refs.createSheetModal.open();
    },
    openHistorizationModal() {
      this.month = new Date().getMonth();
      this.$refs.historizationModal.open();
    },
    createSheet() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        const sheet = {
          ...this.sheet,
        };

        if (sheet.oscarSubProjectName) {
          sheet.oscarSubProjectName.sheet = { id: sheet.id };
        }

        axios.post("/sheets", sheet).then((response) => {
          if (response.data.success) {
            this.sheet = {};
            this.$refs.sheetList.refresh();
            this.$refs.createSheetModal.close();
            this.$router.push({
              name: "Sheet",
              params: { id: response.data.id },
            });
          }
        });
      });
    },
    historizeBudgetControlsForProjects() {
      this.$refs.historizationForm.validate().then((success) => {
        if (!success) {
          return;
        }

        const referenceDate = new Date(this.year + '-' + this.month + '-01 23:59:00')

        console.log(referenceDate);
        // return;

        const payload = {
          projects: this.projectsToHistorize,
          referenceDate: referenceDate,
        };

        this.$store.dispatch("App/historizeProjects", payload);
        this.$refs.historizationModal.close();
      });
    },
    resetRadForProjects() {
      this.$refs.resetRadForm.validate().then((success) => {
        if (!success) {
          return;
        }

        return axios
          .post(`/projects/resetrad`, { projects: this.projectsToHistorize })
          .then((response) => {
            if (response.data.success) {
              this.$refs.resetRadModal.close();
            }
          });
      });
    },
    validateSheets() {
      axios
        .put(`/sheets/validate?selected=${this.selectedToUrl}`)
        .then((res) => {
          if (res.data.success) {
            this.$refs.sheetList.refresh();
            this.sheetsSelected = [];
          }
        });
    },
  },
};
</script>

<style scoped></style>
